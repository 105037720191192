<template>
  <div >
    <div class="profile-set" v-if="profileType === 'list'">
      <div class="profile-img"><div class="profile-img-bg w-30"><image-alt v-if="item.userThumbnail" :src="item.userThumbnail" altType="profile"></image-alt></div></div>
      <div class="profile-info">
        <div><span class="nickname">{{ item.nickName }}</span></div>
      </div>
    </div>
    <div class="profile-set" v-else-if="profileType === 'detail'">
      <div class="profile-img"><div class="profile-img-bg w-50"><image-alt v-if="item.userThumbnail" :src="item.userThumbnail" altType="profile"></image-alt></div></div>
      <div class="profile-info">
        <div><span class="nickname">{{ item.nickName }}</span></div>
        <div class="hit-date">
          <span class="profile-dot">조회수 {{ item.view ? Number(item.view).numberFormat() : 0 }}</span>
          <span class="profile-dot">{{ $getDateFormat(item.createDate, 'Y.m.d') }}</span>
        </div>
      </div>
    </div>
    <div class="profile-img" v-else-if="profileType === 'comment'">
      <image-alt v-show="item && item.userThumbnail" :src="item && item.userThumbnail" altType="profile"></image-alt>
      <image-alt v-show="url" :src="url" altType="profile"></image-alt>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile",
  props: ['profileType', 'item', 'url'],
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>
